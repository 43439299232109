.messageBox{
    width: 100%;
    background: white; 
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
}
.eachMessage{
   
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-bottom: 40px;
}
.firstCol{
    width: 20%;
}

.firstCol p{
    color: #909090;
}

.secondCol{
    width: 78%;
}

.line{
    width: 100%;
    border-bottom: 1px solid #D6D6D6; 
    margin-top: 10px; 
}

.coverTable{
    width: 100%;
}
.headTable{
    width: 100%;
    padding: 15px 20px;
    background-color: #D5FFD5;
    margin-top: 30px;
    margin-bottom: 20px;
}
.headTable p{
    color: #4D4F5C;
    font-size: 15px;
    font-weight: 700;
}

.coverTable table{
    width: 100%;
}
.table thead{
  padding: 10px 15px;
}
.table thead tr th{
  color: #4D4F5C;
  font-size: 13px;
  padding: 7px;
  font-weight: 800;
  text-align: left;
}
.table tbody{
    
    background: white;
}
.table tbody tr td{
    text-align: left;
    padding: 10px;
}
.aboutAssignee{
    display: flex;
    align-items: center;
}
.aboutAssignee img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.aboutAssignee .nameAssign p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #4D4F5C;
}

.aboutAssignee .nameAssign .postAssign {
    font-size: 12px;
    margin-bottom: 8px;
    margin: 0;
    color: #4D4F5C;
    opacity: 0.5;
  }

  .datePlace{
      vertical-align: top;
  }
  .dateAssign{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #4D4F5C;

  }
  .datePlace .moment{
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #4D4F5C;
    opacity: 0.5;

  }
  .datePlace .location{
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #4D4F5C;
    opacity: 0.5;
  }
  .commentRow{

  }
  .commentRow .comment{
      font-size: 14px;
      color: #6F6F6F;
  }
  .down{
      margin-top: 10px;

  }
  .followUp{
      width: 120px;
      padding: 8px 0;
      background: #1C811C;
      font-size: 12px;
      color: white;
      border-radius: 3px;
      margin-bottom: 8px;
      cursor: pointer;
  }
  .expand{
      cursor: pointer;
      width: 200px;
      background: #FF7108;
      color: white;
      margin-top: 10px;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 8px 0;
  }
  .queryBox{
      width: 100%;
      display: none;
  }
  .queryBox .form{
      width: 100%;
      
  }
  .queryBox form textarea{
      width: 100%;
      padding: 10px;
  }
  .queryBox .form button{
    width: 120px;
    padding: 8px 0;
    background: #1C811C;
    color: white;
    border-radius: 3px;
    margin-top: 15px;
  }

  .querylist{
      width: 100%;
      display: none;
      height: 200px;
      overflow-y: auto;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
  }
  .eachQuery{
      width: 100%;
      margin-bottom: 30px;
      
  }
  .eachQuery .nameCover{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    
  }
  .nameCover img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.nameCover .nameFull p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #4D4F5C;
}

.nameCover .nameFull .post {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    color: #4D4F5C;
    opacity: 0.5;
  }
  .queryReact{
    width: 120px;
    padding: 10px 0;
    background: #1C811C;
    color: white;
    border-radius: 3px;
    margin-bottom: 30px;
}